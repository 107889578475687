// COMPONENTS
import React from 'react'
import Layout from 'layout/Layout'
import SEO from 'layout/SEO'
import Features from 'LandingPage/Features'
// CONTENT
import content from 'content/f8.yml'

// *** SUPER SECRET F8 (fate) page *** //
// AKA LINKS TO ALL MY SECRET SHIT
const F8Page = () => {
  const { fate } = content
  return (
    <Layout>
      <SEO title='f8' image='images/branding/android-chrome-192x192.png' />
      {fate && <Features content={fate} />}
    </Layout>
  )
}

export default F8Page
